<template>

    <div v-if="state.isActive == true" class="mt-4">
        <!-- DETAILS  -->
        <WFLink :method="() => { state.openPanel = !state.openPanel }" class="uppercase mb-4">{{
            $t('message.' + state.formData.title) }}</WFLink>

        <div :class="{ hidden: state.openPanel == true }" v-if="state.loaded == true">

            <!-- eslint-disable -->
            <LabelFieldListWState v-model:formData="state.formData.data" :fieldArray="state.fieldlist" />

            <!-- eslint-enable -->

        </div>
    </div>
</template>


<script setup>
import WFLink from './WFLink.vue';
import LabelFieldListWState from './LabelFieldListWState.vue';

import TenantConfigHandler from '@/misc/TenantConfigHandler';
import { useRoute } from "vue-router";
import axios from "@/misc/axios";
import { ref, defineModel, watch, onMounted } from 'vue';

import customFieldsMain from '../../../misc/remFormdata/workflow/custom_fields/customFieldsMain';

let route = useRoute();

const props = defineProps(['groupFormData'])

const taskType = defineModel('taskType');
const taskState = defineModel('taskState');
const customFieldSaveEvent = defineModel('customFieldSaveEvent');
const validation = defineModel('validation');

const state = ref({});
state.value.isActive = false
state.value.formData = customFieldsMain[props.groupFormData]
state.value.openPanel = false;
state.value.loaded = false;
state.value.newTask = false;


watch(() => taskType.value,
    (newValue) => {
        if (state.value.formData.typeIds.includes(newValue)) {
            state.value.isActive = true;
            checkTaskValidation();
            setMeUp();
        } else {
            state.value.isActive = false
            validation.value = true;
        }
    });

watch(() => customFieldSaveEvent.value.counter,
    () => {


        saveCustomFields(customFieldSaveEvent.value.id)
    });




const setMeUp = async () => {
    state.value.newTask = route.params.taskId == 0;

    /*const resp = await axios.get(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/workflow/task/type/5/custom-fields",
        {
            onTokenRefreshed: () => {
                this.setMeUp(state);
            },
        }
    );

    if (resp && resp.status == 200) {
       

    }*/

    state.value.formData.data = {}
    state.value.fieldlist = [];
    state.value.formData.form.forEach((e) => {
        state.value.formData.data[e.model] = e;
        state.value.fieldlist.push(e.model)
    })

    state.value.formData.data.state = {
        value: taskState.value
    }
    if (route.params.taskId != 0) {
        try {
            const resp = await axios.get(
                TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                "/rem/workflow/task/" + route.params.taskId + "/custom-fields",
                {
                    onTokenRefreshed: () => {
                        this.setMeUp(state);
                    },
                }
            );

            if (resp && resp.status == 200) {

                if (resp.data.length == 0) {
                    state.value.loaded = true
                    return;
                }

                const found = resp.data.find((element) => element.groupName == state.value.formData.groupName);
                found.customFields.forEach((e) => {


                    if (state.value.formData.data[e.customFieldViews[0].code]) {
                        state.value.formData.data[e.customFieldViews[0].code].id = e.customFieldViews[0].customFieldValueId
                        
                        if (state.value.newTask == false) {

                            state.value.formData.data[e.customFieldViews[0].code].value = e.customFieldViews[0].value

                            if (e.customFieldViews[0].valueDto) {
                                state.value.formData.data[e.customFieldViews[0].code].valueLabel = e.customFieldViews[0].valueDto[state.value.formData.data[e.customFieldViews[0].code].backendName]
                            }
                        }else{
                            state.value.formData.data[e.customFieldViews[0].code].value = '';
                        }


                    }

                })

            }


        } catch (e) {
            console.log(e);

        }

    }

    state.value.loaded = true
    setTimeout(() => {
        checkTaskValidation();
    }, 1000);



}

const saveCustomFields = async (id) => {

    if (!id || id == 0) {
        return
    }

    if (state.value.newTask == true) {
        await setMeUp();
    }
    for (const [key] of Object.entries(state.value.formData.data)) {
        if (key == 'state' || state.value.formData.data[key].defaultReadonly == true || state.value.formData.data[key].value == "" || state.value.formData.data[key].value == null || state.value.formData.data[key].value == 'null') continue;

        const hermes = {}

        hermes[state.value.formData.data[key].backendType.toLowerCase() + "Value"] = state.value.formData.data[key].value
        try {
            await axios.put(
                TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                "/rem/workflow/custom-field/value/" + state.value.formData.data[key].id, hermes,
                {
                    onTokenRefreshed: () => {
                        this.saveCustomFields();
                    },
                }
            );


        } catch (e) {
            console.log(e);

        }
    }

}



onMounted(() => {

    if (state.value.formData.typeIds.includes(taskType.value)) {
        
        state.value.isActive = true
        setMeUp();


    }

})

for (const [e] of Object.entries(state.value.formData.data)) {


    if ((state.value.formData.data[e].validated == true || state.value.formData.data[e].validated == undefined) && (state.value.formData.data[e].mandatory == false || state.value.formData.data[e].mandatory == undefined))
        continue;

    watch(() => state.value.formData.data[e].validated,
        () => {
            checkTaskValidation();
        });
}


const checkTaskValidation = () => {
    let lever = true;

    for (const [e] of Object.entries(state.value.formData.data)) {
        if ((state.value.formData.data[e].mandatory == false || state.value.formData.data[e].mandatory == undefined)) continue;

        if (state.value.formData.data[e].validated === false) {

            lever = false

        }

    }
    validation.value = lever


}






</script>