<template>
    <div>
        <input :type="type" class="input input-sm input-bordered w-full" :max="type == 'date' ? '9999-12-31' : null"
            v-model="inputValue" @focusout="focus = false" :id="`${title}${type}`" :step="type == 'number'? 'any' : null"/>
    </div>
</template>

<script setup>
import { defineModel } from "vue";

defineProps(["type", 'title']);

const inputValue = defineModel("inputValue");
const focus = defineModel("focus");
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
</style>
