<template>
    <div class="grid grid-cols-[70%_30%] gap-0">
        <div class="flex justify-start pl-2">{{ props.item.capability }}</div>
        <div class="flex justify-center pl-2"><input class="toggle toggle-secondary" type="checkbox" v-model="checked"></div>
    </div>
</template>


<script setup>
    import { defineProps, ref, watch } from 'vue';
    const props = defineProps({
        item: {
            type: Object,
            required: true
        },
        changeFunc : {
            type: Function,
            required: true
        }
    });
    const checked = ref(props.item.enabled);
    watch(checked, (newValue) => {
        props.changeFunc(props.item.capability, newValue);
    });
</script>