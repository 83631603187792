export default [
  {
    title: "siteDesignPlaceholder",
    value: "",
    optionSearchEndpoint: "siteDesign",
    model: "siteDesignId",
    type: "optionSearchEndpoint",
  },
  /*{
    title: "paymentObligations",
    value: "",
    model: "paymentObligation",
    type: "text",
    // TODO: imi után
  },*/
  {
    title: "numberOfStairways",
    value: "",
    model: "numberOfStairways",
    type: "integer",
  },
  {
    title: "numberOfElevators",
    value: "",
    model: "numberOfElevators",
    type: "integer",
  },
  {
    title: "structureTypes",
    type: "label",
  },
  {
    title: "foundation",
    value: "",
    valueLabel: "",
    model: "foundation",
    type: "option",
    optionValue: "FOUNDATION",
  },
  {
    title: "bearingMasonry",
    value: "",
    valueLabel: "",
    model: "bearingMasonry",
    type: "option",
    optionValue: "BEARING_MASONRY",
  },
  {
    title: "slabStructInter",
    value: "",
    valueLabel: "",
    model: "slabStructureInter",
    type: "option",
    optionValue: "SLAB_STRUCTURE_INTERMEDIATE",
  },
  {
    title: "slabSubStructInter",
    value: "",
    valueLabel: "",
    model: "slabSubStructureInter",
    type: "option",
    optionValue: "SLAB_SUB_STRUCTURE_INTERMEDIATE",
    onchangeMethod: (
      options,
      optionsRepo,
      value,

      inputValue
    ) => {
      options.value = optionsRepo.filter((elem) => elem.parent == value);
      if (options.value.filter((elem) => elem.value == inputValue.value)) {
        inputValue.value = "";
      }
    },
  },
  {
    title: "slabStructClosing",
    value: "",
    valueLabel: "",
    model: "slabStructureClosing",
    type: "option",
    optionValue: "SLAB_STRUCTURE_CLOSING",
  },
  {
    title: "slabSubStructClosing",
    value: "",
    valueLabel: "",
    model: "slabSubStructureClosing",
    type: "option",
    optionValue: "SLAB_SUB_STRUCTURE_CLOSING",
    onchangeMethod: (
      options,
      optionsRepo,
      value,

      inputValue
    ) => {
      options.value = optionsRepo.filter((elem) => elem.parent == value);
      if (options.value.filter((elem) => elem.value == inputValue.value)) {
        inputValue.value = "";
      }
    },
  },
  {
    title: "roofType",
    value: "",
    valueLabel: "",
    model: "roofType",
    type: "option",
    optionValue: "ROOF_TYPE",
  },
  {
    title: "flatRoof",
    value: "",
    valueLabel: "",
    model: "flatRoof",
    type: "option",
    optionValue: "FLAT_ROOF",
    visibilityCondition: (newValue/*, oldValue*/) => {
      return newValue == 62
      //62 -  nincs (lapostető)
    }
  },

  {
    title: "roofing",
    value: "",
    valueLabel: "",
    model: "roofing",
    type: "option",
    optionValue: "ROOFING",
  },
  {
    title: "insulation",
    value: "",
    valueLabel: "",
    model: "insulation",
    type: "option",
    optionValue: "INSULATION",
  },
];
