<template>
    <div id="WorkflowMainPage" class="h-screen max-h-screen">
        <BaseLayout>
            <template #leftButtons>

            </template>
            <template #mainContent>
                <div>
                    <RemFormBackbutton :method="() => { moveAwayConfirm(); }"></RemFormBackbutton>
                    <h2 class="text-lg font-sans font-semibold uppercase text-white mb-8">
                        {{ $t(`message.taskAssign`) }}
                    </h2>
                </div>


                <div class="flex flex-row">
                    <TransitionGroup name="fade">
                        <RemFormCard class="mr-8 w-8/12" key="mainCard">
                            <template #remcardheader>

                            </template>
                            <template #remcardmain>
                                <!-- eslint-disable -->
                                <LabelFieldList v-model:formData="state.formdata" :fieldArray="['task']">
                                </LabelFieldList>
                                <RemPrimaryButtonSmall :label="$t('message.add')" :method="() => {

                                }"></RemPrimaryButtonSmall>

                                <!-- eslint-enable -->
                            </template>

                        </RemFormCard>
                        <RemFormCard class="mr-8 w-8/12" key="mainCard">
                            <template #remcardheader>

                            </template>
                            <template #remcardmain>
                                <!-- eslint-disable -->

                                <LabelFieldList v-model:formData="state.formdata"
                                    :fieldArray="['responsible', 'deadline', 'comment']">
                                </LabelFieldList>
                                <!-- eslint-enable -->
                            </template>

                        </RemFormCard>
                    </TransitionGroup>
                </div>

                <!-- eslint-disable -->
                <RemFormConfirmationModal v-model:showConfirmation="state.confirmation.showConfirmation"
                    v-model:confirmationTextToken="state.confirmation.confirmationTextToken"
                    v-model:confirmationTrueMethod="state.confirmation.confirmationTrueMethod"
                    v-model:confirmationFalseMethod="state.confirmation.confirmationFalseMethod">
                </RemFormConfirmationModal>
                <!-- eslint-enable -->
                <div style="height: 200rem"></div>
            </template>
        </BaseLayout>
    </div>
</template>

<script setup>
//components
import BaseLayout from '../layouts/BaseLayout.vue'
import RemPrimaryButtonSmall from '../components/REM/RemPrimaryButtonSmall.vue';
import RemFormConfirmationModal from '../components/REM/RemFormConfirmationModal.vue';
import RemFormBackbutton from '../components/REM/RemFormBackbutton.vue';
import LabelFieldList from '../components/REM/Workflow/LabelFieldList.vue';
import RemFormCard from '../components/REM/RemFormCard.vue';

// utils

import router from "@/misc/router";
import { ref } from 'vue';
//import Axios from '@/misc/axios';
//import store from '@/misc/vuex-store'
//import TenantConfigHandler from '@/misc/TenantConfigHandler';
//import { useI18n } from "vue-i18n";
import taskAssign from '../misc/remFormdata/workflow/taskAssign';

//const { t } = useI18n();


const state = ref({})

state.value.confirmation = {
    showConfirmation: false,
    confirmationTextToken: "",
    confirmationTrueMethod: () => { },
    confirmationFalseMethod: () => { },
}

state.value.formdata = {};
state.value.tasks = {}

taskAssign.data.forEach((e) => {
    state.value.formdata[e.model] = e
})



const moveAwayConfirm = () => {
    state.value.confirmation.confirmationTrueMethod = () => {
        state.value.confirmation.showConfirmation = false;
        router.push({
            name: 'WorkflowMainPage'
        })
    };
    state.value.confirmation.confirmationFalseMethod = () => {
        state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.showConfirmation = true;

}
</script>

<style scoped>
.cardBg {
    background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
    padding: 3rem;

    max-width: 100%;
    overflow-x: auto;
    height: fit-content;
}

.im-main-card-starter {
    animation: 0.5s ease-out 0s 1 scaleupY;

}

.im-main-card-starter div {
    animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
    0% {
        transform-origin: left top;
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

.fade-enter-active {
    transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
    transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    padding: 0;
    width: 0;
    opacity: 0;
}
</style>