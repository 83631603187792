

export default {
    title: 'approvalDates',
    groupName: "Jóváhagyás dátuma",
    typeIds : [5],
    data: [],
    form: [
        
        {
            id: "",
            customFieldId: "3",
            backendType: "DATE",
            backendName: "date: ",
            title: 'first_approval_date',
            model:'FIRST_APPROVAL_DATE',
            type: 'date',
            value: '',
            defaultReadonly: true,
        },
        {
            id: "",
            customFieldId: "4",
            backendType: "DATE",
            backendName: "date: ",
            title: 'second_approval_date',
            model:'SECOND_APPROVAL_DATE',
            type: 'date',
            value: '',
            defaultReadonly: true,
        },
        

    ]
}