<template>
    <div class="w-full overflow-x-auto">
        <TransitionGroup name="fade">
            <RemFormCard :key="'PartnerSearch'">
                <template #remcardheader>
                    <RemFormBackbutton :method="props.backFunc"></RemFormBackbutton>
                    <h2 class="text-xl text-secondary w-full font-bold mb-1">
                        {{ $t('message.addNewPartner') }}
                    </h2>
                </template>
                <template #remcardmain>
                    <h3 class="text-secondary w-full font-bold mb-8">
                        {{ $t('message.dataOfPartner') }}
                    </h3>
                    <!--eslint-disable-->
                    <div class="flex flex-row gap-3 w-full mb-4">
                        <div class="form-control w-full mt-0">
                            <ImFormInput v-model:inputValue="state.name" :type="'text'" :mandatory="true" :title="$t('message.name')" />
                        </div>
                    </div>
                    <div class="flex flex-row gap-3 w-full mb-4">
                        <div class="form-control w-full mt-0">
                            <ImFormInput v-model:inputValue="state.mainType" :type="'option'" :title="$t('message.mainType')" :optionValue="'PARTNER_MAIN_TYPE'" />
                        </div>
                    </div>
                    <div class="flex flex-row gap-3 w-full mb-4">
                        <div class="form-control w-full mt-0">
                            <ImFormInput v-model:inputValue="state.subType" :type="'option'" :title="$t('message.subType')"  :optionValue="'PARTNER_SUB_TYPE'"  />
                        </div>
                    </div>
                    <!--eslint-enable-->
                    <div class="mb-4">
                        <RemPrimaryButton class="mr-2" :disabled="validateSearchOptions()" :method="search" :label="$t('message.search')"></RemPrimaryButton>
                        <RemPrimaryButton class="ml-2" :method="props.createFunc" :label="$t('message.create')"></RemPrimaryButton>
                    </div>
                    <h3 class="text-secondary w-full font-bold">{{ t('message.foundList') }}</h3>
                    <RobberVueGrid ref="universalGrid" :config="searchPartnersGridConfigComputed" />
                    <RemPrimaryButton class="ml-2" :method="props.backFunc" :label="$t('message.cancel')"></RemPrimaryButton>
                </template>
            </RemFormCard>
        </TransitionGroup>
    </div>
</template>

<script setup>

import Axios from "@/misc/axios.js";
import TenantConfigHandler from "../../misc/TenantConfigHandler";
import { defineProps, ref } from 'vue';
import { useI18n } from "vue-i18n";
import RemFormCard from "./RemFormCard.vue";
import ImFormInput from "../ImFormInput.vue";
import RemFormBackbutton from "./RemFormBackbutton.vue";
import RemPrimaryButton from "./RemPrimaryButton.vue";
import RobberVueGrid from '../RVG/RobberVueGrid.vue';
import searchPartnersGridConfig from '../../misc/gridConfigs/searchPartnersGridConfig';
import toast from '@/misc/toast.js'
import { computed } from 'vue';

const { t } = useI18n();

const props = defineProps({
    type: {
        required: true,
        type: String
    },
    backFunc: {
        required: true,
        type: Function
    },
    createFunc: {
        required: true,
        type: Function
    },
    id: {
        required: true,
        type: Number
    },
    partnerMainTypeMapping: {
        required: true,
    },
    partnerSubTypeMapping: {
        required: true,
    }
})
const universalGrid = ref();

const state = ref({
    name: '',
    mainType: null,
    subType: null
})
const endpointBase = ref(null);
const search = () => {
    endpointBase.value = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/${props.type}/${props.id}/partners-available`;
    universalGrid?.value?.refresh();
}
const validateSearchOptions = () => {
    return !state?.value?.name;
}
const searchPartnersGridConfigComputed = computed(() => {
    return searchPartnersGridConfig(t, props.type, props.id, props.partnerMainTypeMapping, 
    props.partnerSubTypeMapping, assignPartner, endpointBase.value, 
    state.value.name, state.value.mainType, state.value.subType);
})

const assignPartner = (partnerId) => {
    Axios.put(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/rem/' + props.type + '/' + props.id + '/partner/' + partnerId)
    .then(() => {
        toast.success(t('message.successfulSave'));
        props?.backFunc();
    })
    .catch((error) => {
        console.log(error);
        toast.error(t('message.error'));
    });
}

</script>