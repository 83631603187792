<template>
    <div>
        <WFLink :method="() => { state.open = !state.open }" class="uppercase mb-4">{{ $t('message.activity') }}
        </WFLink>
        <div v-if="state.open == true">
            <div class="flex flex-row">
                <WFLink :method="() => { state.tab = 1 }" class="mr-4 hover:cursor-pointer"
                    :class="(state.tab == 1) ? 'wf-active' : ''">{{ $t('message.comments') }}</WFLink>
                <WFLink :method="() => { state.tab = 2 }" class="mr-4 hover:cursor-pointer"
                    :class="(state.tab == 2) ? 'wf-active' : ''">{{ $t('message.events') }}</WFLink>
                <WFLink :method="() => { state.tab = 4 }" class="hover:cursor-pointer"
                    :class="(state.tab == 4) ? 'wf-active' : ''">{{ $t('message.documents') }}</WFLink>
            </div>
            <hr>

            <!-- Documents -->
            <div v-if="state.tab == 4">
                <TaskDocument
                    :endpoint="TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/workflow/task/${route.params.taskId}/documents`">
                </TaskDocument>
            </div>

            <!-- History -->
            <div v-if="state.tab == 2">
                <TaskHistory></TaskHistory>
            </div>

            <!-- COMMENTS -->
            <div v-if="state.tab == 1">

                <!-- eslint-disable -->
                <WFTitleField v-model:inputValue="state.newComment" :config="{ title: 'comment', type: 'text' }"
                    textsm="true" class="my-4">
                </WFTitleField>
                <!-- eslint-enable -->
                <RemPrimaryButtonSmall v-if="state.newComment != ''" :label="$t('message.comment')" class="mr-4"
                    :method="() => { saveComment(0, null) }"></RemPrimaryButtonSmall>
                <RemPrimaryButtonSmall v-if="state.newComment != ''" :label="$t('message.cancel')"
                    :method="cancelCommentConfirm">
                </RemPrimaryButtonSmall>
                <div v-for="(item, index) in state.comments" v-bind:key="item.id" class="my-4">
                    <p class="text-secondary">{{ item.userName }}</p>
                    <p class="label-text-alt">{{ item.createdAt }}</p>
                    <hr>
                    <p v-if="item.editing == false" class="wf-comment-text text-white">{{ item.comment }}</p>
                    <RemPrimaryButtonSmall v-if=" User.getUserName() == item.userName && item.editing == false" :label="$t('message.edit')"
                        :method="() => { item.editing = User.getUserName() == item.userName }">
                    </RemPrimaryButtonSmall>

                    <div v-if="item.editing == true">
                        <textarea v-model="item.commentToEdit" class="my-4 w-full ">
                </textarea>
                        <RemPrimaryButtonSmall :label="$t('message.save')" class="mr-4"
                            :method="() => { saveComment(item.id, index); }">
                        </RemPrimaryButtonSmall>
                        <RemPrimaryButtonSmall :label="$t('message.cancel')" class="mr-4"
                            :method="() => { cancelCommentEditConfirm(item) }">
                        </RemPrimaryButtonSmall>
                        <RemPrimaryButtonSmall :label="$t('message.delete')"
                            :method="() => { deleteCommentConfirm(item.id, index) }">
                        </RemPrimaryButtonSmall>
                    </div>
                </div>
            </div>
        </div>
        <!-- eslint-disable -->
        <RemFormConfirmationModal v-model:showConfirmation="state.confirmation.showConfirmation"
            v-model:confirmationTextToken="state.confirmation.confirmationTextToken"
            v-model:confirmationTrueMethod="state.confirmation.confirmationTrueMethod"
            v-model:confirmationFalseMethod="state.confirmation.confirmationFalseMethod">
        </RemFormConfirmationModal>
        <!-- eslint-enable -->

    </div>

</template>

<script setup>
import WFTitleField from '../../components/REM/Workflow/WFTitleField.vue'
import WFLink from '../../components/REM/Workflow/WFLink.vue';
import RemPrimaryButtonSmall from '../../components/REM/RemPrimaryButtonSmall.vue';
import TaskDocument from './TaskDocument.vue';
import TaskHistory from './TaskHistory.vue';
import RemFormConfirmationModal from '../../components/REM/RemFormConfirmationModal.vue';


import { ref, } from 'vue';

import workflowTaskUtils from './workflowTaskUtils';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import User from '../../misc/User';





const route = useRoute();
const { t } = useI18n();



const state = ref({});
state.value.comments = []
state.value.newComment = "";
state.value.open = true
state.value.tab = 1
state.value.confirmation = {
    showConfirmation: false,
    confirmationTextToken: "",
    confirmationTrueMethod: () => { },
    confirmationFalseMethod: () => { },
}

workflowTaskUtils.getComments(state, route.params.taskId)

//utils

const cancelCommentConfirm = function () {
    state.value.confirmation.confirmationTextToken = "abortCommentCreate";
    state.value.confirmation.confirmationTrueMethod = () => {
        cancelComment()
        state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.confirmationFalseMethod = () => {
        state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.showConfirmation = true;
}

const cancelComment = function () {
    state.value.newComment = '';
}

const cancelCommentEditConfirm = function (item) {
    state.value.confirmation.confirmationTextToken = "abortCommentEdit";
    state.value.confirmation.confirmationTrueMethod = () => {
        cancelCommentEdit(item)
        state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.confirmationFalseMethod = () => {
        state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.showConfirmation = true;
}

const cancelCommentEdit = function (item) {
    item.commentToEdit = item.comment;
    item.editing = false
}

const saveComment = async (id, index) => {

    const hermes = {
        comment: index == null ? state.value.newComment : state.value.comments[index].commentToEdit
    };

    let resp = await workflowTaskUtils.saveUniversal(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/workflow/task/" + route.params.taskId + "/comment",
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/workflow/task/comment/" + id,
        hermes,
        id,
        t)
    if (resp != false) {
        resp.data.commentToEdit = resp.data.comment;
        resp.data.editing = false;

        if (id == 0) {
            state.value.comments.push(resp.data)
            state.value.newComment = ""
        } else {
            state.value.comments[index].comment = state.value.comments[index].commentToEdit;
            state.value.comments[index].editing = false
        }
    }
}

const deleteCommentConfirm = (id, index) => {
    state.value.confirmation.confirmationTextToken = "deleteConfirm";
    state.value.confirmation.confirmationTrueMethod = () => {
        deleteComment(id, index)
        state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.confirmationFalseMethod = () => {
        state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.showConfirmation = true;
}

const deleteComment = async (id, index) => {

    let resp = await workflowTaskUtils.deleteUniversal(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/workflow/task/comment/" + id,
        t)
    if (resp != false) {
        state.value.comments.splice(index, 1)
    }
}


</script>

<style scoped>
.wf-title {
    padding: 10px 0 10px 0;
    border-radius: 10px;
}

.wf-comment-text {
    padding: 10px 0 10px 0;
    border-radius: 10px;
}

.wf-title-hover:hover {
    background-color: rgba(0, 0, 0, .3);
}

.wf-active {
    @apply text-secondary
}
</style>