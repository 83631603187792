import router from "../../misc/router"


export default {
    checkAccess(state, view){
        if(state.value.taskId == 0 && view != 'new'){
            router.push({
                name:'permissionDenied'
            })
        }

        if(state.value.taskId != 0 && view == 'new'){
            router.push({
                name:'permissionDenied'
            })
        }
    }
}