<template>
  <div class="flex flex-col w-[412px]" v-if="aggregationRules && aggregationRules.length > 0">
    <h2 class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow m-2">
      <span class="text-white">{{ $t('message.aggregations') }}</span>
    </h2>
    <div v-for="(aggregationRule, index) in aggregationRules" :key="index">

      <DefaultDisplayer :entity="entity" :entityType="type" :spotType="'aggregation'" :spotEndpointBase="'/spots/aggregation/'" :itemData="{gistType: `AGG[${aggregationRule.gistType}]`, name: aggregationRule.name, misc: aggregationRule.aggregationFunction, id: aggregationRule.id}" class="mb-3">
        <template #icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-6 h-6 mr-1">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z" />
            <rect x="10" y="10" width="4" height="4" stroke="currentColor" stroke-width="1" fill="none" />
          </svg>
        </template>
      </DefaultDisplayer>
    </div>
  </div>
</template>

<script>
  import Utils from '../../misc/Utils';
  import DefaultDisplayer from './displayers/DefaultDisplayer.vue';

  export default {
    name: 'AggregationBaseComponent',
    props: {
      entity: {
        type: Object,
        required: true
      },
      type: {
        type: String,
        required: true
      }
    },
    components: {
      DefaultDisplayer,
    },
    mounted() {
      this.getAggregationRules()
    },
    data() {
      return {
        aggregationRules: null
      }
    },
    methods: {
      getAggregationRules() {
        if(this.aggregationRules) return;

        Utils.getAggregationsByTypeAndId(this.type, this.entity.id, (response) => {
          this.aggregationRules = response.data;
        });
      }
    }
  }
</script>
