<template>
  <div class="text-white flex flex-col gap-1" v-if="metaDatas && metaDatas.length > 0">
    <MetaComponent v-for="metaData in metaDatas" :key="metaData.id" :metaData="metaData" :clickOnEdit="() => { clickOnEdit(metaData) }" :clickOnRemove="() => { clickOnRemove(metaData) }"></MetaComponent>
  </div>
  <div class="text-xs alert" v-else>
    {{ $t("message.noMetaData") }}
  </div>
</template>

<script>
import Utils from '../../../misc/Utils';
import MetaComponent from './MetaComponent.vue';
import Axios from '@/misc/axios.js'
import TenantConfigHandler from '@/misc/TenantConfigHandler';

export default {
  name: 'ListMetaComponent',
  props: {
    clickOnEdit: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      metaDatas: null
    }
  },
  components: {
    MetaComponent
  },
  methods: {
    getMetaDatas() {
      if(this.metaDatas) return;
      Utils.getMetaDataByTypeAndId(this.sidebarData.params.entityType, this.sidebarData.entity.id, (response) => {
        this.metaDatas = response.data;
      });
    },
    async clickOnRemove(metaData) {
      let that = this;
      await Axios.delete(TenantConfigHandler.getVariable('ADMINV2_API_URL') + '/system/' + metaData.id + '/remove-metadata').then(() => {
        that.metaDatas = null;
        that.getMetaDatas();
      });
    }
  },
  computed: {
    sidebarData() {
      var entity = this.$store.getters.getSidebarEntity;
      return entity;
    },
    sidebarEntity() {
      return this.sidebarData.entity;
    }
  },
  mounted() {
    this.getMetaDatas();
  }
}
</script>