export default {
  data: [
    {
      title: "floorArea",
      value: "",
      model: "floorArea",
      type: "number",
    },
    {
      title: "legalRelationshipUtilization",
      value: "",
      model: "legalRelationship",
      type: "option",
      optionValue: "UTILIZATION_LEGAL_RELATIONSHIP",
    },
    {
      title: "legalRelationshipStart",
      value: "",
      model: "legalRelationshipStart",
      type: "date",
    },
    {
      title: "legalRelationshipEnd",
      value: "",
      model: "legalRelationshipEnd",
      type: "date",
    },
    {
      title: "partner",
      value: "",
      optionSearchEndpoint: "partner",
      model: "partnerId",
      type: "optionSearchEndpoint",
      mandatory: true,
      validated:false
    },
  ]
}