import RVGLocalStorageService from './RobberVueGrid.LocalStorage.js';

export default {
  titlesVisible() {
    var enabledColumns = false;
    if(this.config.columnSelector && this.config.columnSelector.active) {
      enabledColumns = RVGLocalStorageService.getService().getStorageValue('columns_' + this.config.columnSelector.gridUniqueId);
      if(enabledColumns) {
        enabledColumns = enabledColumns.split(',');
      }
    }

    var titles = {};
    for(var i in this.config.mapping) {
      if(this.config.mapping[i].visible) {
        if(!enabledColumns || (typeof enabledColumns == "object" && enabledColumns.indexOf(i) > -1)) {
          titles[i] = {
            key: i,
            title: this.config.mapping[i].title,
            orderable: this.config.mapping[i].orderable,
            filterable: this.config.mapping[i].filterable,
            filterable_config: this.config.mapping[i].filterable_config,
            width: this.config.mapping[i].width
          };
        }
      }
    }

    return titles;
  },
  mappingVisible() {

    var enabledColumns = false;
    if(this.config.columnSelector && this.config.columnSelector.active) {
      enabledColumns = RVGLocalStorageService.getService().getStorageValue('columns_' + this.config.columnSelector.gridUniqueId);
      if(enabledColumns) {
        enabledColumns = enabledColumns.split(',');
      }
    }

    var mapping = {};
    for(var i in this.config.mapping) {
      if(this.config.mapping[i].visible) {
        if(!enabledColumns || (typeof enabledColumns == "object" && enabledColumns.indexOf(i) > -1)) {
          mapping[i] = this.config.mapping[i];
        }
      }
    }

    return mapping;
  },
  needsToShowTopFiltersBar() {
    return (this.config.filtering && this.config.filtering.active) || this.needsToShowAdditionalOperationsDropdown;
  },
  needsToShowAdditionalOperationsDropdown() {
    return (this.config.columnSelector && this.config.columnSelector.active) 
    || (this.config.refreshable && this.config.refreshable.autoActive && this.config.refreshable.autoValues && this.config.refreshable.autoValues.length)
    || (this.config.pagination && this.config.pagination.possiblePageSizes)
    || (this.config.xlsxExport && this.config.xlsxExport.active);
  },
  visibleColumnsNumber() {
    var num = 0;
    for(var i in this.config.mapping) {
      if(this.config.mapping[i].visible) {
        ++num;
      }
    }

    return num;
  },
  axiosParams() {
    const params = new URLSearchParams();

    if(!this.config.orderLocal) {
      if(this.order.active) {
        params.append('sort', [this.order.field + "," + this.order.direction]);
      }
      else {
        if(this.config.defaultOrderKey && this.config.defaultOrderDirection) {
          params.append('sort', [this.config.defaultOrderKey + "," + this.config.defaultOrderDirection]);
        }
      }
    }

    if(this.pagination.active) {
      params.append('page', this.pagination.page);
      params.append('size', this.pagination.size);
    }

    if(!this.config.filtering.local) {
      if(this.filters && this.filters.length > 1) {
        params.append('filter_operator', this.filterOperator);
      }

      var newFilters = [];
      for(var i in this.filters) { // convert everything to string
        var cFilter = JSON.parse(JSON.stringify(this.filters[i]));
        newFilters.push(cFilter);
      }

      if(newFilters.length > 0) {
        params.append('filters', JSON.stringify(newFilters));

        if(!params.get('filter_operator')) {
          params.append('filter_operator', 'OR');
        }
      }
    }

    return params;
  },
  paginationStart() {
    let p = this.pagination.totalElements > 0 ? this.pagination.page * this.pagination.size + 1 : this.pagination.page * this.pagination.size;
    return p
  },
  paginationEnd() {
    let p = (this.pagination.page+1) * this.pagination.size;
    if(p > this.pagination.totalElements) {
      p = this.pagination.totalElements
    }

    return p
  },
  isPaginationBackDisabled() {
    if(this.pagination.page <= 0) {
      return true;
    }

    return false;
  },
  isPaginationNextDisabled() {
    if((this.pagination.page+1) >= this.pagination.totalPages) {
      return true;
    }

    return false;
  },
  allChecked: {
    get() {
      return this.records.length == this.checkedRows.length
    },
    set() {
    }
  },
  isExtraRowEnabled() {
    if(this.config && this.config.extraRow && this.config.extraRow.active) {
      return true;
    }
    else {
      return false;
    }
  },
  isExtraRowMultiOpenEnable() {
    if(this.config && this.config.extraRow && this.config.extraRow.multiOpen) {
      return true;
    }
    else {
      return false;
    }
  },
  colNumber() {
    var colNumber = this.visibleColumnsNumber;

    if(this.config.bulkOperation && this.config.bulkOperation.active) {
      ++colNumber;
    }

    if(this.config && this.config.extraRow && this.config.extraRow.active) {
      ++colNumber;
    }

    return colNumber
  },
  filteredOrderedRecords() {
    var filteredRecords = [];
    if(this.config.filtering && this.config.filtering.local) {
      for(var i in this.records) {
        if(this.isRecordMatchTheCurrentFiltering(this.records[i])) {
          filteredRecords.push(this.records[i]);
        }
      }
    }
    else {
      filteredRecords = this.records;
    }

    if(this.config.orderLocal) {
      if(this.order.active) {
        filteredRecords = this.sortRecords(filteredRecords, this.order.field, this.order.direction);
      }
      else {
        if(this.config.defaultOrderKey && this.config.defaultOrderDirection) {
          filteredRecords = this.sortRecords(filteredRecords, this.config.defaultOrderKey, this.config.defaultOrderDirection);
        }
      }
    }

    return filteredRecords;
  }
}