<template>
    <div>
        <div class="flex flex-row w-full ">
            <!-- eslint-disable -->
            <WFLabelField v-model:inputValue="state.documentSetup.documentName"
                :config="{ title: 'name', type: 'text' }" class="w-full mr-4">
            </WFLabelField>
            <WFLabelField v-model:inputValue="state.documentSetup.documentDateOfOrigin"
                :config="{ title: 'dateOfOrigin', type: 'date' }" class="w-full ml-4">
            </WFLabelField>
            <!-- eslint-enable -->
        </div>
        <div class="flex flex-row w-full ">
            <!-- eslint-disable -->
            <WFLabelField v-model:inputValue="state.documentSetup.documentType"
                :config="{ title: 'mainType', type: 'option', valueLabel: state.documentSetup.documentTypeLabel, optionValue: 'DOCUMENT_TYPE', searchable: false }"
                :title="$t('message.mainType')" type="option" class="w-full mr-4">
            </WFLabelField>
            <WFLabelField v-model:inputValue="state.documentSetup.documentSubType"
                v-model:parent="state.documentSetup.documentSubTypeParent" :config="{
                    title: 'subType',
                    type: 'option',
                    valueLabel: state.documentSetup.documentSubTypeLabel,
                    optionValue: 'DOCUMENT_SUB_TYPE',
                    searchable: true,
                    onchangeMethod: (options, optionsRepo, value, inputValue,) => {
                        options.value = optionsRepo.filter((elem) => elem.parent == value);
                        if (options.value.filter((elem) => elem.value == inputValue.value)) {
                            inputValue.value = '';
                        }
                     
                    },
                }" class="w-full ml-4">
            </WFLabelField>
            <!-- eslint-enable -->
        </div>
        <input
            class="file:p-2 file:bg-gray-800 file:border-none file:hover:cursor-pointer block w-full text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mb-4"
            aria-describedby="file_input_help" :accept="allowedExtensions" id="file_input" type="file"
            ref="documentFile" />

        <RemPrimaryButtonSmall class="mb-4" :label="$t('message.upload')" :method="uploadFile"></RemPrimaryButtonSmall>

        <hr class="mb-8" />
        <RobberVueGrid v-if="!state.forceRefresh" ref="documentsGrid" :config="documentsGridConfigComputed" />

        <input type="checkbox" id="document_preview" class="modal-toggle" v-model="state.documentPreview.open" />
        <div class="modal " role="dialog">
            <div class="modal-box im-preview-modal-box">
                <h3 class="text-lg font-bold mb-4">{{ state.documentPreview.title }}</h3>
                <iframe class="w-full " style="height: 80%; " :src="state.documentPreview.url"></iframe>
                <div class="modal-action">
                    <label for="document_preview" class="btn"> {{ $t("message.close") }}</label>
                </div>
            </div>
        </div>
        <EditDocumentModal @refreshGrid="refreshGrid" :showModal="modalStatus" :id="state.docuIdForEdit" />
    </div>
</template>

<script setup>
//Components

import RobberVueGrid from '../../components/RVG/RobberVueGrid.vue';
import EditDocumentModal from '../../components/REM/EditDocumentModal.vue';
import WFLabelField from '../../components/REM/Workflow/WFLabelField.vue';
import RemPrimaryButtonSmall from '../../components/REM/RemPrimaryButtonSmall.vue';

//Utils
import Utils from '../../misc/Utils.js';
import store from "@/misc/vuex-store";
import { ref, watch, computed } from 'vue';
import { computedAsync } from '@vueuse/core';
import RVGUtils from "../../components/RVG/RobberVueGridUtils.js";
import { useI18n } from "vue-i18n";
import axios from "@/misc/axios";
import toast from "@/misc/toast.js";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import { useRoute } from 'vue-router';

//rvgs
import documentsGridConfig from '../../misc/gridConfigs/workflowPage/genericDocumentsGridConfig.js';


const props = defineProps(['endpoint'])

const { t } = useI18n();
const route = useRoute();

const state = ref({
    taskId: route.params.taskId,
    docuIdForEdit: "",
    documentSetup: {
        documentName: "",
        documentDateOfOrigin: "",
        documentType: "",
        documentSubType: "",
        documentTypeLabel: "",
        documentSubTypeLabel: "",
        documentSubTypeParent: ""
    },
    documentPreview: {
        title: "",
        id: "",
        url: "",
        open: false
    },
    forceRefresh: false,
});
const documentFile = ref("");
const documentsGrid = ref();

const documentsGridConfigComputed = computedAsync(async () => {
    const [
        typeMapping,
        subTypeMapping,
    ] = await Promise.all([
        RVGUtils.getFormatterMappingForOptionValue("DOCUMENT_TYPE"),
        RVGUtils.getFormatterMappingForOptionValue("DOCUMENT_SUB_TYPE"),
    ])
    return documentsGridConfig(state, props.endpoint, t, editDocument, typeMapping, subTypeMapping);
}, documentsGridConfig(state, props.endpoint, t, editDocument));

async function editDocument(id) {
    const response = await Utils.fetchDocumentById(id);

    store.commit("setIsModalOpen", true);
    store.commit("setDocumentById", response);
    state.value.docuIdForEdit = id;
}

const allowedExtensions = computed(() => {
    return store.getters.getAllowedExtensions
})


const modalStatus = computed(() => {
    return store.getters.getIsModalOpen;
});

const uploadFile = async function () {
    const formData = new FormData();

    formData.append("mainType", state.value.documentSetup.documentType);
    formData.append("subType", state.value.documentSetup.documentSubType);
    formData.append("document", documentFile.value.files[0]);
    formData.append("name", state.value.documentSetup.documentName);
    formData.append(
        "dateOfOrigin",
        state.value.documentSetup.documentDateOfOrigin
    );

    try {
        const resp = await axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        `/rem/workflow/${state.value.taskId}/document`,
        formData)
        if(resp.status == 200){
            toast.success(t("message.successfulSave"));
        documentFile.value = "";
        for (const [key] of Object.entries(state.value.documentSetup)) {
            state.value.documentSetup[key] = ""
        }
        documentsGrid.value.refresh()
        }else{
            toast.error(t("message.notSuccesfulSave"))
        }
        
    } catch (error) {
     
      if(error.response.status == 406){
        toast.error(t("message.fileFormatError") + allowedExtensions.value + t("message.fileFormatError2"))
      }else if(error.response.status == 413){
        toast.error(t("message.fileSizeError"));
      }else{
        toast.error(t("message.saveError"));
      }
    }
}

const refreshGrid = () => {
  state.value.forceRefresh = true;
  setTimeout(() => {
    state.value.forceRefresh = false;
  }, 100);
};

//watchers

watch(() => state.value.documentSetup.documentType,
    () => {
        state.value.documentSetup.documentSubTypeParent = state.value.documentSetup.documentType;

    });
</script>

<style>
.im-preview-modal-box {
    width: 75%;
    min-width: 75%;
    height: 75%;
}
</style>