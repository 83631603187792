let uniqueField = 'buildingsGrid';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import store from "@/misc/vuex-store";

export default (state, t, propertyId, toBuildingFn, basicDataMainFunctionMapping, basicDataStatusMapping, basicDataSapMapping, basicDataFoundationMapping, basicDataBearingMasonryMapping, basicDataSlabStructureInterMapping, basicDataSlabSubStructureInterMapping, basicDataSlabStructureClosingMapping, basicDataSlabSubStructureClosingMapping, basicDataFlatRoofMapping, basicDataRoofingMapping, basicDataRoofTypeMapping, basicDataInsulationMapping) => {
  return {
    endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/property/${propertyId}/buildings`,
    idkey: "id",
    defaultOrderKey: 'id',
    defaultOrderDirection: 'desc',
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100]
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: 'server', // server or local/null
      serverGridKey: 'worksGridColumns'
    },
    xlsxExport: {
      active: true
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [{
        key: '30sec',
        refreshInterval: 30,
        title: t('message.rvg-automatic-list-refresh-30-sec'),
        default: true
      }, {
        key: '1min',
        refreshInterval: 60,
        title: t('message.rvg-automatic-list-refresh-1-min'),
      }, {
        key: '5min',
        refreshInterval: 300,
        title: t('message.rvg-automatic-list-refresh-5-min'),
      }]
    },
    mapping: {
      "$operations": {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: '50px',
        formatter: {
          type: 'Operations',
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: '',
                tooltip: t('message.open'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12,8V4L20,12L12,20V16H4V8H12Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'buildingForwardButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  toBuildingFn(record.id)
                }
              })

              buttons.push({
                title: '',
                tooltip: t('message.edit'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'buildingEditButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  state.value.buildingEdit = true;
                  
                  state.value.buildingId = record.id;
                  state.value.siteDesignName = record.siteDesignName;
                    state.value.buildingData.forEach((e) => {
                      if (e.type == "label") return;
                      if (e.type == "option") {          
                        e.value = record[e.model + "Id"];
                        e.valueLabel = record[e.model + "Value"];
                      } else {
                        e.value = record[e.model];
                      }
                    });

                    state.value.buildingBasicData.forEach((e) => {
                      if (e.type == "label") return;
                      if (e.type == "option") {          
                        e.value = record["basicData"+e.model.charAt(0).toUpperCase()+e.model.slice(1) + "Id"];
                        e.valueLabel = record["basicData"+e.model.charAt(0).toUpperCase()+e.model.slice(1) + "Value"];
                      } else {
                        e.value = record["basicData"+e.model.charAt(0).toUpperCase()+e.model.slice(1)];
                      }
                    })
                    store.commit("setRemTab", 13);
                    store.commit("setRemFormEdit", false);
                }
              })

              return buttons;
            }
          }
        }
      },
      "id": {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: false,
        orderable: true,
      },
      "siteDesignName": {
        title: t("message.siteDesignPlaceholder"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.siteDesignName ? record.siteDesignName : 'N/A'
        }
      },
      "basicDataMainFunctionValue": {
        title: t("message.mainFunction"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataMainFunctionValue ? record.basicDataMainFunctionValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataMainFunctionMapping,
        },             
      },
      "basicDataFloorArea": {
        title: t("message.floorArea"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataFloorArea ? record.basicDataFloorArea : 'N/A'
        },             
      },
      "basicDataMtArea": {
        title: t("message.mtArea"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataMtArea ? record.basicDataMtArea : 'N/A'
        },             
      },
      "basicDataPremiseNumber": {
        title: t("message.numberOfPremises"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataPremiseNumber ? record.basicDataPremiseNumber : 'N/A'
        },             
      },
      "basicDataIsAccessible": {
        title: t("message.isAccessible"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }            
      },
      "basicDataNumberDedicated": {
        title: t("message.numberDedicated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataNumberDedicated ? record.basicDataNumberDedicated : 'N/A'
        },             
      },
      "basicDataNumberCanBeSeated": {
        title: t("message.numberCanBeSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataNumberCanBeSeated ? record.basicDataNumberCanBeSeated : 'N/A'
        },             
      },
      "basicDataNumberSeated": {
        title: t("message.numberSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataNumberSeated ? record.basicDataNumberSeated : 'N/A'
        },             
      },
      "basicDataMaxHeight": {
        title: t("message.buildingMaxHeight"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataMaxHeight ? record.basicDataMaxHeight : 'N/A'
        },             
      },
      "basicDataStatusValue": {
        title: t("message.status"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataStatusValue ? record.basicDataStatusValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataStatusMapping,
        },            
      },
      "basicDataFloorsBelowSurface": {
        title: t("message.floorsBelowSurface"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataFloorsBelowSurface ? record.basicDataFloorsBelowSurface : 'N/A'
        },             
      },
      "basicDataFloorsAboveSurface": {
        title: t("message.floorsAboveSurface"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataFloorsAboveSurface ? record.basicDataFloorsAboveSurface : 'N/A'
        },             
      },
      "basicDataAttic": {
        title: t("message.attic"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }            
      },
      "basicDataAtticCanBeUsed": {
        title: t("message.atticCanBeUsed"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }           
      },
      "basicDataSapValue": {
        title: t("message.sapBuildingId"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataSapValue ? record.basicDataSapValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataSapMapping,
        },              
      },
      "basicDataTcafm": {
        title: t("message.buildingMarking"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataTcafm ? record.basicDataTcafm : 'N/A'
        },             
      },
      "basicDataParcelNumber": {
        title: t("message.parcelNumber"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataParcelNumber ? record.basicDataParcelNumber : 'N/A'
        },             
      },
      "basicDataOperation": {
        title: t("message.operation"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataOperation ? record.basicDataOperation : 'N/A'
        },             
      },
      "basicDataCleaner": {
        title: t("message.cleaning"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataCleaner ? record.basicDataCleaner : 'N/A'
        },             
      },
      "basicDataSupplies": {
        title: t("message.supplies"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataSupplies ? record.basicDataSupplies : 'N/A'
        },             
      },
      "basicDataTui": {
        title: 'TU',
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataTui ? record.basicDataTui : 'N/A'
        },             
      },
      "basicDataOwnerOfAssets": {
        title: t("message.ownerOfAssets"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataOwnerOfAssets ? record.basicDataOwnerOfAssets : 'N/A'
        },             
      },
      "basicDataAssetManager": {
        title: t("message.assetManager"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.basicDataAssetManager ? record.basicDataAssetManager : 'N/A'
        },             
      },
      "numberOfStairways": {
        title: t("message.numberOfStairways"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.numberOfStairways ? record.numberOfStairways : 'N/A'
        },             
      },
      "numberOfElevators": {
        title: t("message.numberOfElevators"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.numberOfElevators ? record.numberOfElevators : 'N/A'
        },             
      },
      /*"paymentObligation": {
        title: t("message.paymentObligations"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.paymentObligation ? record.paymentObligation : 'N/A'
        },             
      },*/
      "foundationValue": {
        title: t("message.foundation"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.foundationValue ? record.foundationValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataFoundationMapping,
        },             
      },
      "bearingMasonryValue": {
        title: t("message.bearingMasonry"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.bearingMasonryValue ? record.bearingMasonryValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataBearingMasonryMapping,
        },             
      },
      "slabStructureInterValue": {
        title: t("message.slabStructInter"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.slabStructureInterValue ? record.slabStructureInterValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataSlabStructureInterMapping,
        },             
      },
      "slabSubStructureInterValue": {
        title: t("message.slabSubStructInter"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.slabSubStructureInterValue ? record.slabSubStructureInterValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataSlabSubStructureInterMapping,
        },                
      },
      "slabStructureClosingValue": {
        title: t("message.slabStructClosing"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.slabStructureClosingValue ? record.slabStructureClosingValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataSlabStructureClosingMapping,
        },             
      },
      "slabSubStructureClosingValue": {
        title: t("message.slabSubStructClosing"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.slabSubStructureClosingValue ? record.slabSubStructureClosingValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataSlabSubStructureClosingMapping,
        },               
      },
      "flatRoofValue": {
        title: t("message.flatRoof"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.flatRoofValue ? record.flatRoofValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataFlatRoofMapping,
        },             
      },
      "roofTypeValue": {
        title: t("message.roofType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.roofTypeValue ? record.roofTypeValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataRoofTypeMapping,
        },             
      },
      "roofingValue": {
        title: t("message.roofing"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.roofingValue ? record.roofingValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataRoofingMapping,
        },             
      },
      "insulationValue": {
        title: t("message.insulation"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record?.insulationValue ? record.insulationValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataInsulationMapping,
        },  
      },
    }
  }
}
