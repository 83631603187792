<template>
    <div class="w-full overflow-x-auto">
        <TransitionGroup name="fade">
            <!---   Lista  -->
            <RemFormCard v-if="store.getters.getRemTab == rvgTab">
                <template #remcardheader>
                    <h2 class="text-xl text-secondary w-full font-bold mb-8">
                        {{ title }}
                    </h2>
                    <div class="flex flex-row justify-between">
                        <RemPrimaryButton class="mb-8" :method="addNewMethod"
                            :label="props.addNewTitle ? $t('message.' + props.addNewTitle) : $t('message.addNew')">
                        </RemPrimaryButton>
                        <label class="tooltip tooltip-left !z-[999]" :data-tip="$t('message.allRelatedPartner')">
                            <SideMenuButton v-if="partnerTypeSwitcherMethod" :onClick="partnerTypeSwitcherMethod"
                                :icon="'partner_mode_switch_icon'">
                            </SideMenuButton>
                        </label>
                    </div>


                </template>
                <template #remcardmain>
                    <RobberVueGrid ref="universalGrid" :config="rvgConfig" />
                </template>
            </RemFormCard>

            <!---   szerkesztés   -->
            <RemFormCard v-if="store.getters.getRemTab == formTab">
                <template #remcardheader>
                    <RemFormBackbutton :method="backMethod"></RemFormBackbutton>
                    <h2 class="text-xl text-secondary w-full font-bold">
                        {{ title }}
                    </h2>
                </template>
                <template #remcardmain>
                    <div v-for="item in formData" v-bind:key="item.model" class="mb-8">
                        <div v-if="store.getters.getRemFormEdit == false">
                            <!-- eslint-disable -->
                            <RemVisibilityWrapper v-model:toWatch="item.parent"
                                :visibilityCondition="item.visibilityCondition">
                                <ImFormLabel :item="item"></ImFormLabel>
                            </RemVisibilityWrapper>
                            <!-- eslint-enable -->
                        </div>
                        <div v-if="store.getters.getRemFormEdit == true">
                            <!-- eslint-disable -->
                            <ImFormInputVisibilityWraped v-model:inputValue="item.value" v-model:parent="item.parent"
                                v-model:toWatch="item.parent" v-model:validated="item.validated" :config="item">
                            </ImFormInputVisibilityWraped>
                            <!-- eslint-enable -->
                        </div>
                    </div>

                    <!-- eslint-disable -->
                    <RemFormButtonRowStored :saveMethod="saveMethod" :deleteMethod="deleteMethod"
                        v-model:saveValidation="saveValidation">
                    </RemFormButtonRowStored>
                    <!-- eslint-enable -->
                </template>
            </RemFormCard>
        </TransitionGroup>

    </div>
</template>

<script setup>
import RobberVueGrid from "../RVG/RobberVueGrid.vue";
import RemFormBackbutton from "./RemFormBackbutton.vue";
import RemPrimaryButton from "./RemPrimaryButton.vue";
import RemFormButtonRowStored from "./RemFormButtonRowStored.vue";
import RemFormCard from "./RemFormCard.vue";
//import ImFormInput from "../ImFormInput.vue";
import ImFormLabel from "../ImFormLabel.vue";
import SideMenuButton from "./SideMenuButton.vue";
import ImFormInputVisibilityWraped from "./ImFormInputVisibilityWraped.vue";
import RemVisibilityWrapper from "./RemVisibilityWrapper.vue";

import store from "@/misc/vuex-store";
import { defineModel, watch, ref } from 'vue';


const props = defineProps(['title', 'rvgTab', 'formTab', 'addNewTitle', 'addNewMethod', 'saveMethod', 'deleteMethod', "backMethod", "rvgConfig", "partnerTypeSwitcherMethod"])
const formData = defineModel('formData')
const saveValidation = defineModel('saveValidation')

const universalGrid = ref()

if (store.getters.getRemFormEdit == props.rvgTab) {
    store.commit('setRemFormEdit', false)
} else if (store.getters.getRemFormEdit == props.formTab) {
    store.commit('setRemFormEdit', true)
}

watch(() => props.rvgConfig, () => {
    universalGrid?.value?.refresh()
})

</script>

<style scoped>
.cardBg {
    background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
    padding: 3rem;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    height: fit-content;
}

.im-main-card-starter {
    animation: 0.5s ease-out 0s 1 scaleupY;

}

.im-main-card-starter div {
    animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
    0% {
        transform-origin: left top;
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

@keyframes fadeinbtn {
    0% {
        opacity: 0;
        display: flex;

    }

    50% {
        opacity: 0;

    }

    100% {
        opacity: 100%;

    }
}

@keyframes fadeoutbtn {
    0% {
        opacity: 100%;
        display: flex;

    }

    100% {
        opacity: 0;
        display: none;

    }
}

@keyframes widenbtn {
    0% {
        width: 10rem;
    }

    50% {
        width: 10rem;
    }

    100% {
        width: 18rem;
    }
}

.im-sidemenu-contanier {
    transition: width 0.5s;
    animation: 0.5s ease-out 0s 1 scaleupY;
    padding: 1rem;
    height: fit-content;
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemnu-row {
    width: 14rem;
}

.im-w-auto {
    margin-right: 2rem;
    width: 10rem;
}

.im-w-auto .tooltip-bottom {

    display: none;
}

.im-w-auto:hover {

    width: 18rem;

    .tooltip-bottom {
        animation: 0.5s ease-out 0s 1 fadeinbtn;
        display: flex;
    }
}


.im-sidemenu-contanier div {
    animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
    padding-top: 2rem;
}

.fade-enter-active {
    transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
    transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    padding: 0;
    width: 0;
    opacity: 0;
}

:deep(.im-round-btn) {
    border-radius: 9999px;
}

/* button */


.im-button-active {
    border-color: lighter hsl(var(--s));
    ;
}

.im-button-disabled {
    border-color: #a6adbb;
    color: #a6adbb;
    background-color: rgba(0, 0, 0, 0);
}
</style>