<template>
    <div>
        <div v-for="item in state.historyData" v-bind:key="item.id" class="my-4">
            <p class=" "><span class="text-white">{{ item.userName ? item.userName : item.processName }}</span> {{$t(`message.changed_this_field`)}}
                <span class="text-white">{{ $t(`message.${item.title}`) }}</span> </p>
            <p class="label-text-alt">{{ item.date }}</p>

            <p class=" label-text-alt text-white">{{  item.valueFromName }} 
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                        class="bi bi-arrow-right inline" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg>
                </span>
                {{ item.valueToName }}
            </p>

        </div>

    </div>
</template>

<script setup>

import { ref, onMounted } from 'vue'
import { useRoute } from "vue-router";
import historyDummy from './historyDummy';

let route = useRoute();

const state = ref({});

state.value.taskId = route.params.taskId;
state.value.historyData = []

onMounted(() => {

    if (state.value.taskId) {
        state.value.historyData = historyDummy;
        state.value.historyData = []


    }
})




</script>