<template>
  <div class="p-0 h-full overflow-y-scroll">
    <div class="p-5 pb-2 mr-2 flex flex-col grow border-b-0 border-base-300">
      <div class="flex flex-row items-center gap-2 justify-center">
        <button class="text-white" @click="() => { backButtonFunction(); }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </button>
        <h2 class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow">
          <span class="text-white">{{ sidebarEntity.displayName ? sidebarEntity.displayName : sidebarEntity.name }}</span> -
          <span v-if="state.currentView == 'list'">{{ $t("message.listOfMetaData") }}</span>
          <span v-if="state.currentView == 'add'">{{ $t("message.addMetaData") }}</span>
          <span v-if="state.currentView == 'edit'">{{ $t("message.editMetaData") }}</span>
        </h2>
        <a href="javascript:void(null)" class="hover:text-secondary" @click="hideSidebar">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </a>
      </div>
    </div>

    <div class="px-5 text-center">
      <AddNewOrEditMetaComponent v-if="state.currentView == 'add' || state.currentView == 'edit'" :sbstate="state" :backToList="backToList" />
      <ListMetas v-if="state.currentView == 'list'" :clickOnEdit="clickOnMetaEdit" />
      <button v-if="state.currentView == 'list'" class="btn btn-sm text-white font-light text-xs mt-3" @click="() => { state.currentView = 'add'; }">{{ $t("message.addNew") }}</button>
    </div>
  </div>
</template>

<script setup>
  import AddNewOrEditMetaComponent from './MetaEditorSidebarContentComponents/AddNewOrEditMetaComponent.vue';
  import ListMetas from './MetaEditorSidebarContentComponents/ListMetas.vue';
  import { computed } from 'vue';
  import store from '@/misc/vuex-store';
  import { reactive } from 'vue';

  const state = reactive({
    editedId: null,
    year: 0,
    day: 0,
    month: 0,
    monthlyOfficeRentPricePerSqm: 0.0,
    monthlyOfficeOperationalPricePerSqm: 0.0,
    usedOfficeSpaceRatePerSqm: 0.0,
    officeSpaceRate: 0.0,
    electricityCostPerKwh: 0.0,
    electricityBasePricePerGj: 0.0,
    electricityCostPerSqm: 0.0,
    heatingBasePricePerGj: 0.0,
    heatingCostPerSqm: 0.0,
    currency: '',
    oneEurToCurrency: 0.0,
    currentView: 'list',
    siteDesignId: null
  });

  const hideSidebar = () => {
    store.commit('hideSidebar');
  }

  const resetEditData = () => {
    state.year = 0;
    state.day = 0;
    state.month = 0;
    state.monthlyOfficeRentPricePerSqm = 0.0;
    state.monthlyOfficeOperationalPricePerSqm = 0.0;
    state.usedOfficeSpaceRatePerSqm = 0.0;
    state.officeSpaceRate = 0.0;
    state.electricityCostPerKwh = 0.0;
    state.electricityBasePricePerGj = 0.0;
    state.electricityCostPerSqm = 0.0;
    state.heatingBasePricePerGj = 0.0;
    state.heatingCostPerSqm = 0.0;
    state.currency = '';
    state.oneEurToCurrency = 0.0;

  }

  const backToList = () => {
    resetEditData();
    state.currentView = 'list';
  }

  const backButtonFunction = () => {
    if(state.currentView == 'add' || state.currentView == 'edit') {
      backToList();
      return;
    }

    var entity = store.getters.getSidebarEntity;
    if(entity.params && entity.params.back) {
      entity.params.back();
    }
  }

  const clickOnMetaEdit = (metaData) => {
    state.year = metaData.year;
    state.day = metaData.day;
    state.month = metaData.month;

    state.monthlyOfficeRentPricePerSqm = metaData.monthlyOfficeRentPricePerSqm;
    state.monthlyOfficeOperationalPricePerSqm = metaData.monthlyOfficeOperationalPricePerSqm;
    state.usedOfficeSpaceRatePerSqm = metaData.usedOfficeSpaceRatePerSqm;
    state.officeSpaceRate = metaData.officeSpaceRate;
    state.electricityCostPerKwh = metaData.electricityCostPerKwh;
    state.electricityBasePricePerGj = metaData.electricityBasePricePerGj;
    state.electricityCostPerSqm = metaData.electricityCostPerSqm;
    state.heatingBasePricePerGj = metaData.heatingBasePricePerGj;
    state.heatingCostPerSqm = metaData.heatingCostPerSqm;
    state.currency = metaData.currency;
    state.oneEurToCurrency = metaData.oneEurToCurrency;
    state.editedId = metaData.id;
    state.siteDesignId = metaData.siteDesignId;
    state.currentView = 'edit';
  }

  const sidebarEntity = computed(() => {
    var entity = store.getters.getSidebarEntity;
    return entity.entity;
  });
</script>