<template>
    <div v-if="show == true">
        <slot></slot>
    </div>
</template>

<script setup>
import { useRoute } from "vue-router";

import { ref, watch, defineModel } from 'vue';

let route = useRoute();

const props = defineProps(['viewList', 'stateList', 'readonlyViewList', 'readonlyStateList', 'defaultReadonly'])
const taskState = defineModel('taskState')
const readonly = defineModel('readonly')

const show = ref(false)

const readonlyViews = ['read']

watch(() => route.params.view, () => {
   

    show.value = checkme(props.viewList, route.params.view) && checkme(props.stateList, taskState.value)
    if (readonlyViews.includes(route.params.view) || props.defaultReadonly == true) {
        readonly.value = true
    } else {
        readonly.value = checkreadonly(props.readonlyViewList, route.params.view) || !checkme(props.readonlyStateList, taskState.value)
    }


})


const checkme = (array, toCheck) => {
    
    if (toCheck == undefined || toCheck == null || array == undefined || array.length == 0) {
        return true
    }
    else {
       
        return array.includes(toCheck)
    }
}

const checkreadonly = (array, toCheck) => {
    if (toCheck == undefined || toCheck == null || array == undefined || array.length == 0) {
        return false
    }
    else {
        
        return array.includes(toCheck)
    }
}

show.value = show.value = checkme(props.viewList, route.params.view) && checkme(props.stateList, taskState.value)
if (readonlyViews.includes(route.params.view)  || props.defaultReadonly == true) {
    readonly.value = true
} else {
    
    
    readonly.value = checkreadonly(props.readonlyViewList, route.params.view) || !checkme(props.readonlyStateList, taskState.value)
}

</script>