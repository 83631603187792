<template>
    <div>
        <div v-for="field in fieldArray" v-bind:key="field">

            <!-- eslint-disable -->
            <WFLabelFieldViewWraped  v-model:formData="formData[field]"></WFLabelFieldViewWraped>
            <!-- eslint-enable -->

        </div>

    </div>

</template>

<script setup>
import WFLabelFieldViewWraped from './WFLabelFieldViewWraped.vue';

import { defineModel } from 'vue';

const formData = defineModel('formData')
defineProps(['fieldArray'])

// 'endPoint', 'displayValue', 'searchable', 'manualOptions', 
// 'onchangeMethod', 'validationMethod', 'optionSearchEndpoint', 'helperText', 'mandatory', 'readonly'


</script>

<style scoped></style>