export default {
  data: [
    {
      title: "meterType",
      value: "",
      valueLabel: "",
      model: "type",
      type: "option",
      optionValue: "METER_TYPE",
    },
    {
      title: "contractingParty",
      value: "",
      optionSearchEndpoint: "partner",
      model: "partnerId",
      type: "optionSearchEndpoint",
    },
    {
      title: "publicUtilityType",
      value: "",
      model: "utilityType",
      type: "option",
      optionValue: "METER_UTILITY_TYPE",
    },
    {
      title: "podIdentifier",
      value: "",
      model: "identifier",
      type: "text",
    },
    {
      title: "meterPartnerType",
      value: "",
      valueLabel: "",
      model: "meterPartnerType",
      type: "option",
      optionValue: "METER_PARTNER_TYPE",
    },
    {
      title: "serialNumber",
      value: "",
      model: "serialNumber",
      type: "text",
    },
    {
      title: "placement",
      value: "",
      model: "placement",
      type: "text",
    },
    {
      title: "commType",
      value: "",
      type: "option",
      model: "commType",
      optionValue: "METER_COMM_TYPE",
    },
  ]
}