<template>
  <div class="text-white w-full">
    <div v-if="state.currentTab == 'data'">
      <div class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t("message.date") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <VueDatePicker format="yyyy MMM dd" :enable-time-picker="false" :time-picker-inline="true"
            :locale="$i18n.locale" :month-change-on-scroll="false" :action-row="{}" :clearable="true"
            select-text="OK" :cancel-text="'Cancel'" :teleport="true" v-model="state.date" model-type="yyyy-MM-dd" :dark="true" />
      </div>
      <div v-if="['system'].includes(sidebarContent.params.entityType)" class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t("message.currency") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input v-model="state.currency" type="text" class="input input-bordered w-full mb-3" />
      </div>

      <div v-if="['system'].includes(sidebarContent.params.entityType)" class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t("message.oneEurToCurrency") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input v-model="state.oneEurToCurrency" type="number" step="0.1" class="input input-bordered w-full mb-3" />
      </div>
      <div v-if="['system', 'site-design'].includes(sidebarContent.params.entityType)" class="form-control w-full mt-0 ">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t("message.monthlyOfficeRentPricePerSqm") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input v-model="state.monthlyOfficeRentPricePerSqm" type="number" step="0.1" class="input input-bordered w-full mb-3" />
      </div>
      <div v-if="['system', 'site-design'].includes(sidebarContent.params.entityType)" class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t("message.monthlyOfficeOperationalPricePerSqm") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input v-model="state.monthlyOfficeOperationalPricePerSqm" type="number" step="0.1" class="input input-bordered w-full mb-3" />
      </div>

      <div v-if="['system', 'site-design'].includes(sidebarContent.params.entityType)" class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t("message.usedOfficeSpaceRatePerSqm") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input v-model="state.usedOfficeSpaceRatePerSqm" type="number" step="0.1" class="input input-bordered w-full mb-3" />
      </div>

      <div v-if="['system', 'site-design'].includes(sidebarContent.params.entityType)" class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t("message.officeSpaceRate") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input v-model="state.officeSpaceRate" type="number" step="0.1" class="input input-bordered w-full mb-3" />
      </div>

      <div v-if="['system'].includes(sidebarContent.params.entityType)" class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t("message.electricityCostPerKwh") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input v-model="state.electricityCostPerKwh" type="number" step="0.1" class="input input-bordered w-full mb-3" />
      </div>

      <div v-if="['system'].includes(sidebarContent.params.entityType)" class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t("message.electricityBasePricePerGj") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input v-model="state.electricityBasePricePerGj" type="number" step="0.1" class="input input-bordered w-full mb-3" />
      </div>

      <div v-if="['system'].includes(sidebarContent.params.entityType)" class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t("message.electricityCostPerSqm") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input v-model="state.electricityCostPerSqm" type="number" step="0.1" class="input input-bordered w-full mb-3" />
      </div>

      <div v-if="['system'].includes(sidebarContent.params.entityType)" class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t("message.heatingBasePricePerGj") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input v-model="state.heatingBasePricePerGj" type="number" step="0.1" class="input input-bordered w-full mb-3" />
      </div>

      <div v-if="['system'].includes(sidebarContent.params.entityType)" class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t("message.heatingCostPerSqm") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input v-model="state.heatingCostPerSqm" type="number" step="0.1" class="input input-bordered w-full mb-3" />
      </div>

      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="w-full mt-3">
          <button :disabled="!validation()" class="disabled:opacity-60 btn btn-sm bg-magenta text-white font-light text-xs border-none" @click="addNewMetaData">
            {{ $t("message.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import Axios from '@/misc/axios';
  import store from '@/misc/vuex-store';
  import { reactive, computed, watch } from 'vue';
  import toast from '@/misc/toast.js'
  import TenantConfigHandler from '@/misc/TenantConfigHandler';
  import VueDatePicker from "@vuepic/vue-datepicker";

  const props = defineProps({
    backToList: {
      type: Function,
      default: () => {}
    },
    sbstate: {
      type: Object,
      required: true
    }
  })

  const state = reactive({
    id: props.sbstate.editedId ?? null,
    siteDesignId: props.sbstate.siteDesignId ?? null,
    date: new Intl.DateTimeFormat('en-CA').format(props.sbstate.year ? new Date(props.sbstate.year, props.sbstate.month -1, props.sbstate.day) : new Date()),
    monthlyOfficeRentPricePerSqm: props.sbstate.monthlyOfficeRentPricePerSqm ?? 0.0,
    monthlyOfficeOperationalPricePerSqm: props.sbstate.monthlyOfficeOperationalPricePerSqm ?? 0.0,
    usedOfficeSpaceRatePerSqm: props.sbstate.usedOfficeSpaceRatePerSqm ?? 0.0,
    officeSpaceRate: props.sbstate.officeSpaceRate ?? 0.0,
    electricityCostPerKwh: props.sbstate.electricityCostPerKwh ?? 0.0,
    electricityBasePricePerGj: props.sbstate.electricityBasePricePerGj ?? 0.0,
    electricityCostPerSqm: props.sbstate.electricityCostPerSqm ?? 0.0,
    heatingBasePricePerGj: props.sbstate.heatingBasePricePerGj ?? 0.0,
    heatingCostPerSqm: props.sbstate.heatingCostPerSqm ?? 0.0,
    currency: props.sbstate.currency ?? '',
    oneEurToCurrency: props.sbstate.oneEurToCurrency ?? 0.0,
    type: props.type,
    currentTab: 'data',
  })

  const sidebarContent = computed(() => {
    var entity = store.getters.getSidebarEntity;
    return entity;
  });

  function addNewMetaData() {
    const entityType = sidebarContent.value.params.entityType;
    const entityId = sidebarContent.value.entity.id ? sidebarContent.value.entity.id : "";
    let uri;

    if (entityType == "system") {
      uri = TenantConfigHandler.getVariable('ADMINV2_API_URL') + '/' + entityType + "/add-metadata";
    } else {
      uri = TenantConfigHandler.getVariable('ADMINV2_API_URL') + '/' + entityType + "/" + entityId + '/add-metadata';
    }
    let curDate = new Date(state.date);
    Axios.post(uri, {
      id: state.id,
      year: curDate.getFullYear(),
      day: curDate.getDate(),
      month: curDate.getMonth() + 1,
      monthlyOfficeRentPricePerSqm: state.monthlyOfficeRentPricePerSqm,
      monthlyOfficeOperationalPricePerSqm: state.monthlyOfficeOperationalPricePerSqm,
      usedOfficeSpaceRatePerSqm: state.usedOfficeSpaceRatePerSqm,
      officeSpaceRate: state.officeSpaceRate,
      electricityCostPerKwh: state.electricityCostPerKwh,
      electricityBasePricePerGj: state.electricityBasePricePerGj,
      electricityCostPerSqm: state.electricityCostPerSqm,
      heatingBasePricePerGj: state.heatingBasePricePerGj,
      heatingCostPerSqm: state.heatingCostPerSqm,
      currency: state.currency,
      oneEurToCurrency: state.oneEurToCurrency,
      siteDesignId: state.siteDesignId
    }, {
      onTokenRefreshed: () => {
        addNewMetaData()
      }
    }).then(() => {
      state.id = null;
      state.siteDesignId = null;
      state.date = '';
      state.monthlyOfficeRentPricePerSqm = 0.0;
      state.monthlyOfficeOperationalPricePerSqm = 0.0;
      state.usedOfficeSpaceRatePerSqm = 0.0;
      state.officeSpaceRate = 0.0;
      state.electricityCostPerKwh = 0.0;
      state.electricityBasePricePerGj = 0.0;
      state.electricityCostPerSqm = 0.0;
      state.heatingBasePricePerGj = 0.0;
      state.heatingCostPerSqm = 0.0;
      state.currency = '';
      state.oneEurToCurrency = 0.0;
      props.backToList();
      toast.success("message.saved")
    })
    .catch(console.error);
  }

  function validation() {
    return state.date !== '' && state.date !== null;
  }
  watch(() =>  [
    props.sbstate.siteDesignId,
    props.sbstate.year,
    props.sbstate.day,
    props.sbstate.month,
    props.sbstate.monthlyOfficeRentPricePerSqm,
    props.sbstate.monthlyOfficeOperationalPricePerSqm,
    props.sbstate.usedOfficeSpaceRatePerSqm,
    props.sbstate.officeSpaceRate,
    props.sbstate.electricityCostPerKwh,
    props.sbstate.electricityBasePricePerGj,
    props.sbstate.electricityCostPerSqm,
    props.sbstate.heatingBasePricePerGj,
    props.sbstate.heatingCostPerSqm,
    props.sbstate.currency,
    props.sbstate.oneEurToCurrency
  ], ([
    newSiteDesignId,
    newYear,
    newDay,
    newMonth,
    newMonthlyOfficeRentPricePerSqm,
    newMonthlyOfficeOperationalPricePerSqm,
    newUsedOfficeSpaceRatePerSqm,
    newOfficeSpaceRate,
    newElectricityCostPerKwh,
    newElectricityBasePricePerGj,
    newElectricityCostPerSqm,
    newHeatingBasePricePerGj,
    newHeatingCostPerSqm,
    newCurrency,
    newOneEurToCurrency
  ]) => {
    state.siteDesignId = newSiteDesignId;
    state.date = new Intl.DateTimeFormat('en-CA').format(new Date(newYear, newMonth, newDay));
    state.monthlyOfficeRentPricePerSqm = newMonthlyOfficeRentPricePerSqm;
    state.monthlyOfficeOperationalPricePerSqm = newMonthlyOfficeOperationalPricePerSqm;
    state.usedOfficeSpaceRatePerSqm = newUsedOfficeSpaceRatePerSqm;
    state.officeSpaceRate = newOfficeSpaceRate;
    state.electricityCostPerKwh = newElectricityCostPerKwh;
    state.electricityBasePricePerGj = newElectricityBasePricePerGj;
    state.electricityCostPerSqm = newElectricityCostPerSqm;
    state.heatingBasePricePerGj = newHeatingBasePricePerGj;
    state.heatingCostPerSqm = newHeatingCostPerSqm;
    state.currency = newCurrency;
    state.oneEurToCurrency = newOneEurToCurrency;
  });

</script>
