<template>
    <!-- eslint-disable -->
    <ViewWrapper v-model:taskState="taskState" v-model:readonly="formData.readonly"
        :viewList="formData.viewList" :stateList="formData.stateList" :readonlyViewList="formData.readonlyViewList" :readonlyStateList="formData.readonlyStateList" :defaultReadonly="formData.defaultReadonly">
        <WFTitleField v-model:inputValue="formData.value" v-model:parent="formData.parent"
            v-model:validated="formData.validated" v-model:readonly="formData.readonly"
            :config="formData" :textsm="textsm"></WFTitleField>
    </ViewWrapper>
    <!-- eslint-enable -->
</template>

<script setup>
import ViewWrapper from './ViewWrapper.vue';
import WFTitleField from './WFTitleField.vue';

import { defineModel } from 'vue';

const formData = defineModel('formData')
const taskState = defineModel('taskState')
defineProps(['textsm'])
</script>