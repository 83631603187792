<template>
  <div>
    <input
      type="text"
      class="input input-sm input-bordered w-full"
      :value="formattedValue"
      @input="onInput"
      @focusout="focus = false"
      :id="`${title}number`"
      step="any"
      autocomplete="off"
    />
  </div>
</template>

<script setup>
import { defineModel, computed, ref } from "vue";

defineProps(["title"]);

const inputValue = defineModel("inputValue");
const focus = defineModel("focus");

const lastValidValue = ref(inputValue.value == null? '' : inputValue.value.toString());

const formattedValue = computed(() => {
  return lastValidValue.value.toString().replace('.', ',');
});

const onInput = (event) => {
  const rawValue = event.target.value;
  
  if (rawValue === '') {
    inputValue.value = null; 
    lastValidValue.value = '';
    return;
  }

  if (rawValue === '-') {
    inputValue.value = null; 
    lastValidValue.value = '-';
    return;
  }

  let normalizedValue = rawValue.replace(',', '.');

  // Prevent leading zeros except for '0,' or '-0,'
  if (/^-?0\d/.test(normalizedValue)) {
    event.target.value = lastValidValue.value.replace('.', ',');
    return;
  }
  
  if (/^-?\d+(\.\d*)?$/.test(normalizedValue)) {
    inputValue.value = parseFloat(normalizedValue);
    lastValidValue.value = normalizedValue;
  } else {
    event.target.value = lastValidValue.value.replace('.', ',');
  }
};

</script>