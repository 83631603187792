<template>
    <div>
        <slot v-if="visibility"></slot>
    </div>
</template>

<script setup>
import { ref, defineModel, watch } from 'vue'

const toWatch = defineModel('toWatch')
const props = defineProps(['visibilityCondition'])
const visibility = ref(true)

if (props.visibilityCondition) {
    visibility.value = props.visibilityCondition(toWatch.value)
    watch(() => toWatch.value, (newValue, oldValue) => {

        visibility.value = props.visibilityCondition(newValue, oldValue)
        

    })
}



</script>