export default [
    {
        id: 1,
        date: "2024-12-05T08:13:53",
        field: "state",
        title: "state",
        userName: "Tesz Elek",
        valueFrom: 1,
        valueFromName: "Új érdeklődés",
        valueTo: 1,
        valueToName: "Új érdeklődés",
    },
    {
        id: 2,
        date: "2024-12-05T08:13:53",
        field: "priority",
        title: "priority",
        userName: "Tesz Elek",
        valueFrom: 'LOW',
        valueFromName: "Low",
        valueTo: 'MEDIUM',
        valueToName: "Medium",
    },
    {
        id: 3,
        date: "2024-12-05T08:13:53",
        field: "title",
        title: "title",
        processName: 'automated',
        valueFrom: "Teszt 01",
        valueFromName: "Teszt 01",
        valueTo: "Teszt 01 01",
        valueToName: "Teszt 01 01",
    },
]