<template>
    <div>
        <SpinnerLoader v-if="loading" />
        <div v-else v-for="item in items" :key="item" class="text-white flex flex-col gap-1 bg-base-200 rounded-s-md p-2">
            <CapabilityComponent :item="item" :changeFunc="onChange" />
        </div>
        <div class="flex items-center mt-3 mb-0 w-full text-center justify-center">
            <button :disabled="saving" @click="save" class="btn btn-sm bg-magenta text-white font-light text-xs border-none">
                {{ $t("message.save") }}
            </button>
        </div>
    </div>
</template>
  
  <script setup>
    import Axios from '@/misc/axios.js'
    import { onMounted } from 'vue';
    import toast from '@/misc/toast.js'
    import TenantConfigHandler from '@/misc/TenantConfigHandler';
    import SpinnerLoader from '../../../components/SpinnerLoader.vue';
    import CapabilityComponent from './CapabilityComponent.vue';
    import { useI18n } from "vue-i18n";
    import { ref } from 'vue';
    const { t } = useI18n();
  
    const loading = ref(true);
    const saving = ref(false);
    const props = defineProps({
      gistId: {
        type: Number,
        default: null
      }
    });
    const items = ref([]);
    onMounted(() => 
    {
        Axios.get(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/gist/' + props.gistId + '/capability').then((response) => {
            items.value = response.data;
            loading.value = false;
        }).catch((error) => {
            console.error(error);
            loading.value = false;
            toast.error(t("message.error"));
        });
    });
    const onChange = (capability, enabled) => {
        let item = items.value.find(i => i.capability === capability);
        if(item)
        {
            item.enabled = enabled;
        }
    }
    const save = () => {
        saving.value = true;
        Axios.post(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/gist/' + props.gistId + '/capability', items.value).then(() => {
            toast.success(t("message.successfulSave"));
            saving.value = false;
        }).catch((error) => {
            console.log(error);
            saving.value = false;
            toast.error(t("message.notSuccessfulSave"));
        });
    }
  </script>